import styled from "styled-components"

const Attribute = styled.h4`
  color: ${props => props.theme.colors.primary.green};
  text-transform: uppercase;
  font-weight: normal;
  font-size: 16px;

  @media (${props => props.theme.mediaQueries.desktop}) {
    font-size: 18px;
  }
`

export default Attribute
