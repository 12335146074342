import styled from "styled-components"

const Line = styled.div`
  height: 1px;
  width: 100%;
  margin-top: 8px;
  grid-column: 1/3;
  background: ${props => props.theme.colors.primary.green};

  @media (${props => props.theme.mediaQueries.largeDesktop}) {
    margin: 0;
    width: 1px;
    height: 100%;
    ${props => props.desktop && "display: none"}
  }
`

export default Line
