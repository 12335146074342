import React, { useContext, useEffect } from "react"
import Context from "../store/Context"

//React components imports
import Layout from "../components/Layout"
import MyReservations from "../components/MyReservations"
import PageTitle from "../components/PageTitle"

import ReservationText from "../components/ReservationText"

const MyReservationPage = props => {
  const { dispatch } = useContext(Context)

  useEffect(() => {
    dispatch({ type: "SET_PATH", path: props.location.pathname })
  }, [dispatch, props.location.pathname])

  return (
    <Layout>
      {/* <PageTitle subtitle="Moje rezervácie">Rezervácia</PageTitle>
      <MyReservations /> */}
      <ReservationText />
    </Layout>
  )
}

export default MyReservationPage
