import styled from "styled-components"

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 20px;
  max-width: 450px;
  margin: 0 auto 24px;
`

export default Wrapper
