import styled from "styled-components"

const Value = styled.div`
  font-size: 16px;
  justify-self: end;
  font-weight: normal;

  @media (${props => props.theme.mediaQueries.desktop}) {
    font-size: 17px;
  }
`

export default Value
